.request h2 {
    font-family: Sumana;
}

.request p {
    font-family: Roboto;
    color: #DDF8E8;
}


.request {
    display: flex;
    background: rgba(1, 1, 1, 0.5);
    padding: calc(5px + 2vmin);
    justify-content: center;
}

  .request-content {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #DDF8E8;
    width: 50%;
    padding: 30px;
    margin: 10px;
    border-radius: 30px;
  }

  .request-myBtn {
    width: 120px;
    font-size: 18px;
    padding: 15px;
    border: none;
    background-color: #946E83;
    color: #DDF8E8;
    cursor: pointer;
    display: flex;
    align-self: center;
    text-align: center;
    display: flex;
    border-radius: 30px;
    font-family: sans-serif;
  }
  
  .request-myBtn:hover {
    background: #CDD5D1;
    color:#615055;
  }