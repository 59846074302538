.contact h2 {
    font-family: Sumana;
}

.contact p {
    font-family: Roboto;
}

.label1, .label2, .label3 {
    height: 30px;
    padding: 15px;
}

.contact {
    display: flex;
    background: rgba(1, 1, 1, 0.5);
    padding: calc(5px + 2vmin);
    justify-content: center;
}

  .contact-content {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #DDF8E8;
    width: 50%;
    padding: 30px;
    margin: 10px;
    border-radius: 30px;
    align-content: space-between;
  }

  .contact-myBtn {
    width: 95px !important;
    font-size: 18px;
    margin: 5px;
    padding: 15px;
    border: none;
    background-color: #946E83;
    color: #DDF8E8;
    cursor: pointer;
    display: flex;
    align-self: center;
    text-align: center;
    display: flex;
    border-radius: 30px;
    font-family: sans-serif;
  }
  
  .contact-myBtn:hover {
    background: #CDD5D1;
    color:#615055;
  }