.hero h1 {
    font-family: Sumana;
}

.hero p {
    font-family: sans-serif;
    font-weight: bold;
    color: #DDF8E8;
}

@media (min-width: 768px) {

.hero {
    display: flex;
    background: black;
    padding: calc(5px + 2vmin);
    background-image: url('./flowers.gif');
    background-repeat: no-repeat; 
    background-size: cover;   
    justify-content: center;
}

#myBtn {
  width: 125px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 18px;
  padding: 15px;
  border: none;
  background-color: black;
  color: #DDF8E8;
  cursor: pointer;
  border-radius: 30px;
}

}

@media screen and (max-width: 768px) {

  .hero {
      display: flex;
      background: black;
      padding: calc(5px + 2vmin);
      background-image: url('./flower-mobile.png');
      background-repeat: no-repeat; 
      background-size: cover;   
      justify-content: center;
  }
  
  }

  .hero-content {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    bottom: 0;
    color: white;
    width: 50%;
    padding: 20px;
    font-weight: 500;
    text-shadow: 
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px  1px 0 #000,
      1px  1px 0 #000; /* Creates a white outline */
      font-weight: bold; /* Optional for better readability */
  }

  #myBtn {
    width: 130px;
    font-family: sans-serif;
    font-size: 18px;
    padding: 15px;
    border: none;
    background-color: black;
    color: #DDF8E8;
    cursor: pointer;
    display: flex;
    align-self: center;
    text-align: center;
    display: flex;
    border-radius: 30px;
  }
  
  #myBtn:hover {
    background: #CDD5D1;
    color:#615055;
  }