@media (min-width: 768px) {
    .footer {
        background: #946E83;
        color: white;
        display: flex;
        flex-flow: row nowrap;
        min-height: 10vmin;
        padding: calc(5px + 2vmin);
        justify-content: space-between;
    }
    }
    
    .active {
        color:#CDD5D1;
    }
    
    .footer-about {
        color:#DDF8E8;
        font-family: sans-serif;
        padding: 5vh;
    }
    
    @media screen and (max-width: 768px) {
        .footer{
            background: #946E83;
            color: white;
            display: flex;
            flex-flow: row wrap;
            min-height: 10vmin;
            justify-content: space-evenly;
            align-items: center;
            padding: calc(2px + 2vmin);
            font-size: 3vmin;
        }
    
        .footer a {
            display: flex;
            flex-flow: column wrap;
            padding: 1vmin;
        }
    }
    
    img {
        height: 5vmin;
    }

    .copyright {
        color: #DDF8E8;
        text-align: center;
        align-items: center;
        align-self: center;
    }

    .copyright-credits{
        color: #DDF8E8;
        text-align: center;
        align-items: center;
        align-self: center;
        font-size: 10px;
    }