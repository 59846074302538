/* Portfolio.css */
.portfolio {
    padding: 50px 20px;
    text-align: center;
    background-color: #000;
    font-family: Sumana;
  }
  
  .portfolio h2 {
    font-size: 2.5rem;
    color: #DDF8E8;
    margin-bottom: 30px;
  }
  
  .marquee {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 120px; /* Adjust height based on logo size */
  }
  
  .marquee-content {
    display: flex;
    gap: 300px; /* Space between logos */
    animation: scroll 120s linear infinite;
    will-change: transform;
    align-content: center;
    align-items: center;
  }
  
  .brand-logo {
    flex: 0 0 auto;
    width: 120px; /* Adjust logo size */
  }
  
  .brand-logo img {
    width: 100%;
    height: auto;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%); /* Moves by half the content width */
    }
  }
  
  @media (max-width: 768px) {
    .brand-logo {
      width: 80px; /* Smaller logos */
    }
    .marquee-content {
      gap: 15px; /* Reduce space */
    }
  }
  