.about h2 {
    font-family: Sumana;
}

.about p {
    font-family: sans-serif;
    font-weight: bold;
    color: #DDF8E8;
}


.about {
    display: flex;
    padding: calc(5px + 2vmin);
    background-color: #946E83;
    background-repeat: no-repeat; 
    background-size: cover;   
    justify-content: center;
}

  .about-content {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    bottom: 0;
    color: white;
    width: 50%;
    padding: 20px;
    background: rgba(0,0,0,0.7);
  }

 