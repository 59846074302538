/* Quote.css */
.quote-slideshow {
    position: relative;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background: #000;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .quote-content {
    opacity: 0;
    position: absolute;
    width: 100%;
    transition: opacity 1.5s fadeinout;
  }
  
  .quote-content.fade {
    opacity: 1;
    position: relative;
  }
  
  h2 {
    font-size: 1.8rem;
    color: #DDF8E8;
    margin-bottom: 10px;
    font-family: Sumana;
  }
  
  p {
    font-size: 1.2rem;
    color: #946E83;
    margin-bottom: 5px;
  }
  
  small {
    font-size: 0.9rem;
    color: #B4A6AB;
  }
  
  /* Circle Indicators */
  .quote-indicators {
    margin-top: 20px;
  }
  
  .quote-indicator {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background: #CDD5D1;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .quote-indicator.active {
    background: #946E83; /* Active circle color */
  }
  
  .quote-slideshow:hover .quote-content {
    animation-play-state: paused;
  }
  
  /* Keyframes for Smoother Transition */
@keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    50% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }