.marketing-services {
    padding: 50px 20px;
    background-color: #615055;
    text-align: center;
    font-family: sans-serif;
  }
  
  .services-title {
    font-size: 2.5rem;
    font-family: Sumana;
    color: #DDF8E8;
    margin-bottom: 30px;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .service-card {
    background: #CDD5D1;
    border: 1px solid #946E83;
    border-radius: 8px;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .service-icon {
    font-size: 3rem;
    margin-bottom: 15px;
    color: #946E83; /* Adjust to your branding color */
  }
  
  .service-title {
    font-size: 1.5rem;
    color: #946E83;
    margin-bottom: 10px;
    font-family: Sumana;
  }
  
  .service-description {
    font-size: 1rem;
    color: #615055;
    line-height: 1.5;
    font-weight: bold;
  }
  