.active {
    color:#CDD5D1;
}

a {
    text-decoration: none;
    align-self: center;
}

.usps-heading h2 {
    font-family: Sumana;
    text-align: center;
    font-size: 30px;
    padding: 20px;
    color: #615055;
}

.usps h3 {
    font-family: Sumana;
    color: #DDF8E8;
}
@media screen and (max-width: 768px) {
.usps {
    color: #DDF8E8;
    display: flex;
    flex-flow: column nowrap;
    background-color: #DDF8E8;
    background-repeat: no-repeat; 
    background-size: cover;   
    padding: 20px;
    font-weight: 500;
  }
}
@media (min-width: 768px) {
    .usps {
        color: #DDF8E8;
        display: flex;
        flex-flow: column nowrap;
        background-color: #DDF8E8;
        background-repeat: no-repeat; 
        background-size: cover;   
        padding: 20px;
        font-weight: 500;
      }
    }

.big-group {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #DDF8E8;
}

@media screen and (max-width: 768px) {
.small-group-1, .small-group-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    color: #DDF8E8;
}
}

@media (min-width: 768px) {
    .small-group-1, .small-group-2 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        color: #DDF8E8;
    }
    }

.usp1, .usp2, .usp3, .usp4, .usp5, .usp6 {
    background-color: rgb(0,0,0, .5);
    padding: 30px;
    margin: 10px;
    color: #DDF8E8;
}

.usps p {
    color: #DDF8E8;
}