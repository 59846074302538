.header {
    text-align: center;
    font-family: Roboto;
  }
  
  .header-logo {
    height: 5vmin;
    pointer-events: none;
    display: flex;
  }
  

  @media (min-width: 768px) {
  .top {
    padding: calc(3px + 1vmin);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    font-size: calc(5px + 2vmin);
    background: rgb(148,110,131);
  }
  .top a {
    text-decoration: none;
    padding: 0 20px 0 20px;
    font-family: Roboto;
    color: white;
  }
  
  #desktop-menu {
    display: block;
  }
  
  }
  
  @media screen and (max-width: 768px) {
    .top {
      padding: calc(3px + 1vmin);
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: calc(5px + 2vmin);
      background: rgb(148,110,131);
    }
  
    .top a {
      text-decoration: none;
      padding: 20px;
      font-family: Roboto;
      color: white;
      flex-flow: column nowrap;
      display: flex;
    }

    #desktop-menu {
        display: none;
      }

  
  }
  
  
  
